import {
	EFxIdSdkAdapterInfoSocialCapability,
	FxIdSdkAdapterSocialSettingDefault,
	FxIdSdkBaseAdapter,
	IExportedFxIdSdkMethod,
	IFxIdSdkAdapterBuyProductRequest,
	IFxIdSdkAdapterBuyProductResponse,
	IFxIdSdkAdapterInfoSocial,
	IFxIdSdkAdapterSocialSettings,
	IFxIdSdkAdapterStatEventRequest,
	IFxIdSdkAdapterStatInitializeRequest
} from "./FxIdSdkBaseAdapter";
import { deferred } from "./FxIdSdkUtils";
import OpenApiClient from "../Api/OpenApiClient";
import {
	IVkAdsCallbackResult,
	IVkAdsConfig,
	IVkExternalApi,
	IVkExternalApiError,
	IVkGetStatusCallbackResult,
	IVkIframeApiCallbacks,
	IVkMerchantParams,
	IVkUserInfoCallbackResult,
	VkLoginStatusUserAuthorizedNotRegistered,
	VkLoginStatusUserNotAuthorized
} from "../vkplay";
import {
	FxIdApplicationStoreCreatePaymentHandlerEmbeddingType,
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesPlayGamePublicConfigResponse
} from "../Api/gen";
import { sharingHandlersStore, userStore } from "../Stores";
import i18next from "i18next";

export class FxIdSdkAdapterForVkPlay extends FxIdSdkBaseAdapter implements IVkIframeApiCallbacks {
	vkExternalApi?: IVkExternalApi;
	private buyProductResolve?: (data: any) => void;
	private buyProductReject?: (data: any) => void;
	private userInfoCallbackResolve?: (data: IVkUserInfoCallbackResult) => void;
	private userInfoCallbackReject?: (reason?: IVkExternalApiError) => void;
	private getLoginStatusCallbackResolve?: (data: IVkGetStatusCallbackResult) => void;
	private getLoginStatusCallbackReject?: (reason?: IVkExternalApiError) => void;
	appid!: number;
	private registerUserCallbackResolve?: (data: IVkUserInfoCallbackResult) => void;
	private registerUserCallbackReject?: (reason?: IVkExternalApiError) => void;
	private userInfo?: IFxIdSdkAdapterInfoSocial;
	private adsCallbackResolve?: (data: IVkAdsCallbackResult) => void;
	private adsCallbackReject?: (
		reason?: "UndefinedAdError" | "AdblockDetectedAdError" | "WaterfallConfigLoadFailed"
	) => void;

	public static VkPlayHost = "vkplay.ru";

	SocialSettings(): Promise<IFxIdSdkAdapterSocialSettings> {
		return Promise.resolve(FxIdSdkAdapterSocialSettingDefault);
	}

	constructor(
		protected exportedSdk: IExportedFxIdSdkMethod,
		protected game: string,
		protected config: FxIdWebFeaturesPlayGamePublicConfigResponse
	) {
		super(exportedSdk);
		const appId = config.PlatformData.VkontaktePlay?.AppId;

		if (appId == null) {
			throw new Error("No AppId for VkontaktePlay adapter");
		}
		this.appid = appId;
	}

	RegisterShareHandlers(): Promise<void> {
		// Это все залипуха. Надо будет менять на нормальные апи
		sharingHandlersStore.getState().enableShareVk(() => {
			const url = `https://vk.com/share.php?url=${window.location}&title=${i18next.t(`seo.${this.game}.title`)}`;
			window.open(url, "__blank");
		});

		sharingHandlersStore.getState().enableShareOk(() => {
			const url = `https://connect.ok.ru/offer?url=${window.location}&title=${i18next.t(
				`seo.${this.game}.title`
			)}`;
			window.open(url, "__blank");
		});

		sharingHandlersStore.getState().enableShareTelegram(() => {
			const url = `https://telegram.me/share/url?url=${window.location}&text=${i18next.t(
				`seo.${this.game}.title`
			)}`;
			window.open(url, "__blank");
		});
		return Promise.resolve();
	}

	async BuyProduct(request: IFxIdSdkAdapterBuyProductRequest): Promise<IFxIdSdkAdapterBuyProductResponse> {
		const url = new URL(window.location.href);
		const currency =
			(url.searchParams.get("currency") as keyof IVkMerchantParams["merchant_param"]["currency"]) ?? "RUB";
		const createPaymentResult = await OpenApiClient.Store.fxIdWebFeaturesStoreCreatePaymentEndpoint({
			Game: this.game,
			Sku: request.sku,
			WebPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.VkontaktePlay,
			EmbeddingType: FxIdApplicationStoreCreatePaymentHandlerEmbeddingType.Embed,
			Currency: currency,
			VkontaktePlay: {
				IpAddress: "0.0.0.0",
				Uid: this.userInfo?.userId ?? url.searchParams.get("uid") ?? "",
				Currency: currency
			}
		});

		log.info("Received result from server: %o", createPaymentResult);

		const { promise, resolve, reject } = deferred<IFxIdSdkAdapterBuyProductResponse>();
		this.buyProductResolve = (data: any) => {
			log.info("Buy product resolved %o", data);
			resolve({ ...data, transactionId: createPaymentResult.TransactionId });
		};
		this.buyProductReject = (data: any) => {
			log.info("Buy product rejected %o", data);
			reject(new Error(data));
		};

		const paymentFrameParams: IVkMerchantParams = {
			merchant_param: {
				amount: createPaymentResult.OrderPrice,
				description: createPaymentResult.OrderProduct.LocalizedProductDescription,
				currency: currency,
				public_order_id: createPaymentResult.TransactionId
			}
		};

		log.info("Requesting payment frame with params", paymentFrameParams);
		this.vkExternalApi?.paymentFrame(paymentFrameParams);

		return promise;
	}

	GetSocialInfo(): Promise<IFxIdSdkAdapterInfoSocial> {
		const { promise, resolve, reject } = deferred<IFxIdSdkAdapterInfoSocial>();
		this.userInfoCallbackResolve = (data: IVkUserInfoCallbackResult) => {
			resolve({
				social: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.VkontaktePlay,
				userId: data.uid.toString(),
				capabilities: []
			});
		};
		this.userInfoCallbackReject = reject;

		this.vkExternalApi?.userInfo();

		return promise;
	}

	GetLoginStatus(): Promise<IVkGetStatusCallbackResult> {
		log.info("Requesting login status");
		const { promise, resolve, reject } = deferred<IVkGetStatusCallbackResult>();
		this.getLoginStatusCallbackResolve = resolve;
		this.getLoginStatusCallbackReject = reject;

		this.vkExternalApi?.getLoginStatus();

		return promise;
	}

	RegisterUser(): Promise<IVkUserInfoCallbackResult> {
		log.info("Requesting register user");
		const { promise, resolve, reject } = deferred<IVkUserInfoCallbackResult>();
		this.registerUserCallbackResolve = resolve;
		this.registerUserCallbackReject = reject;

		this.vkExternalApi?.registerUser();

		return promise;
	}

	async Initialize(): Promise<void> {
		log.info("Initializing VkPlay adapter");

		await new Promise<void>((resolve) => {
			const s = document.createElement("script");
			s.type = "text/javascript";
			s.src = `//vkplay.ru/app/${this.appid}/static/mailru.core.js`;
			s.addEventListener(
				"load",
				function (e) {
					log.info("Script loaded");
					resolve();
				},
				false
			);
			const head = document.getElementsByTagName("head")[0];
			head.appendChild(s);

			log.info("Script added");
		});

		log.info("Initializing api");
		this.vkExternalApi = await window.iframeApi({
			appid: this.appid,
			getLoginStatusCallback: this.getLoginStatusCallback.bind(this),
			userInfoCallback: this.userInfoCallback.bind(this),
			userProfileCallback: this.userProfileCallback.bind(this),
			registerUserCallback: this.registerUserCallback.bind(this),
			paymentFrameUrlCallback: this.paymentFrameUrlCallback.bind(this),
			getAuthTokenCallback: this.getAuthTokenCallback.bind(this),
			paymentReceivedCallback: this.paymentReceivedCallback.bind(this),
			paymentWindowClosedCallback: this.paymentWindowClosedCallback.bind(this),
			userConfirmCallback: this.userConfirmCallback.bind(this),
			adsCallback: this.adsCallback.bind(this)
		});
		log.info("Api initialized");

		const loginStatus = await this.GetLoginStatus();

		log.info("Received login status: " + loginStatus);

		if (loginStatus.loginStatus === VkLoginStatusUserNotAuthorized) {
			this.vkExternalApi.authUser();

			// https://documentation.vkplay.ru/f2p_vkp/f2pb_js_vkp#authUser
			// Авторизация пользователя. При вызове метода появляется окно авторизации.
			// После авторизации страница будет перезагружена, далее можно запросить статус и информацию о пользователе.
			await new Promise((resolve) => {
				log.info("Eternal promise. VkPlay MUST reload page");
			});
			document.location.reload();
		} else if (loginStatus.loginStatus === VkLoginStatusUserAuthorizedNotRegistered) {
			await this.RegisterUser();
			this.vkExternalApi?.reloadWindow();
			document.location.reload();
		}

		this.userInfo = await this.GetSocialInfo();

		userStore.getState().updateUserId(this.userInfo.userId);
		userStore.getState().updateDisplayName(`#${this.userInfo.userId}`);
	}

	StoreCurrency(): Promise<string | undefined> {
		const url = new URL(window.location.href);
		const currency =
			(url.searchParams.get("currency") as keyof IVkMerchantParams["merchant_param"]["currency"]) ?? "RUB";

		return Promise.resolve(currency);
	}

	paymentReceivedCallback = (data: { uid: string }): void => {
		log.info("paymentReceivedCallback");
		this.buyProductResolve?.call(this, {});
	};

	paymentWindowClosedCallback = (): void => {
		log.info("paymentWindowClosedCallback");
		this.buyProductReject?.call(this, { error: "paymentWindowClosed" });
	};

	userInfoCallback = (param: IVkUserInfoCallbackResult | IVkExternalApiError): void => {
		log.info("userInfoCallback", param);
		if (param.status === "error") {
			this.userInfoCallbackReject?.call(this, param);
		} else {
			this.userInfoCallbackResolve?.call(this, param);
		}
	};

	getLoginStatusCallback = (param: IVkGetStatusCallbackResult | IVkExternalApiError): void => {
		log.info("getLoginStatusCallback", param);
		if (param.status === "error") {
			this.getLoginStatusCallbackReject?.call(this, param);
		} else {
			this.getLoginStatusCallbackResolve?.call(this, param);
		}
	};

	registerUserCallback = (param: IVkUserInfoCallbackResult | IVkExternalApiError): void => {
		log.info("registerUserCallback", param);
		if (param.status === "error") {
			this.registerUserCallbackReject?.call(this, param);
		} else {
			this.registerUserCallbackResolve?.call(this, param);
		}
	};

	userProfileCallback = (profile: any) => {
		log.info("userProfileCallback", profile);
	};

	paymentFrameUrlCallback = (url: any): void => {
		log.info("paymentFrameUrlCallback", url);
	};

	getAuthTokenCallback = (token: string): void => {
		log.info("getAuthTokenCallback", token);
	};

	userConfirmCallback = (): void => {
		log.info("userConfirmCallback");
	};

	ShowAds(config: IVkAdsConfig): Promise<IVkAdsCallbackResult> {
		log.info("Requesting showAds");
		const { promise, resolve, reject } = deferred<IVkAdsCallbackResult>();
		this.adsCallbackResolve = resolve;
		this.adsCallbackReject = reject;

		this.vkExternalApi?.showAds(config);

		return promise;
	}

	adsCallback = (param: IVkAdsCallbackResult): void => {
		switch (param.type) {
			case "adCompleted":
				this.adsCallbackResolve?.call(this, param);
				this.exportedSdk.DispatchAdsFinished();
				log.info("adCompleted!");
				break;

			case "adDismissed":
				this.adsCallbackReject?.call(this, param.code);
				this.exportedSdk.DispatchAdsSkipped();
				log.info("adDismissed!");
				break;

			case "adError":
				this.adsCallbackReject?.call(this, param.code);
				this.exportedSdk.DispatchAdsFailed();
				log.error("Error while open video ad:", param.code);
				break;
		}
	};

	override AdsIsVideoReady() {
		window.FxIdSdk!.DispatchAdsVideoAvailable();
		return Promise.resolve(true);
	}

	override async AdsShowVideo() {
		await this.ShowAds({ sources: undefined, interstitial: false });
	}

	StatInitialize(request: IFxIdSdkAdapterStatInitializeRequest): Promise<void> {
		return Promise.resolve(undefined);
	}

	StatEvent(request: IFxIdSdkAdapterStatEventRequest): Promise<void> {
		return Promise.resolve(undefined);
	}
}
