/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGames } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGames';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGames';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteam } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteam';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteam';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlay } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlay';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlay';
import type { FxIdApplicationStoreCreatePaymentHandlerEmbeddingType } from './FxIdApplicationStoreCreatePaymentHandlerEmbeddingType';
import {
    FxIdApplicationStoreCreatePaymentHandlerEmbeddingTypeFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerEmbeddingTypeFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerEmbeddingTypeToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerEmbeddingType';
import type { FxIdDomainStoreEnumsSupportedWebPublishingPlatform } from './FxIdDomainStoreEnumsSupportedWebPublishingPlatform';
import {
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSON,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSONTyped,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformToJSON,
} from './FxIdDomainStoreEnumsSupportedWebPublishingPlatform';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesStoreCreatePaymentRequest
 */
export interface FxIdWebFeaturesStoreCreatePaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    Game: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    Sku: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    Currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    Country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    RedirectUrl?: string | null;
    /**
     * 
     * @type {FxIdDomainStoreEnumsSupportedWebPublishingPlatform}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    WebPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlay}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    VkontaktePlay?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlay | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGames}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    AbsoluteGames?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGames | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteam}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    Steam?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteam | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerEmbeddingType}
     * @memberof FxIdWebFeaturesStoreCreatePaymentRequest
     */
    EmbeddingType: FxIdApplicationStoreCreatePaymentHandlerEmbeddingType;
}

/**
 * Check if a given object implements the FxIdWebFeaturesStoreCreatePaymentRequest interface.
 */
export function instanceOfFxIdWebFeaturesStoreCreatePaymentRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "Game" in value;
    isInstance = isInstance && "Sku" in value;
    isInstance = isInstance && "WebPublishingPlatform" in value;
    isInstance = isInstance && "EmbeddingType" in value;

    return isInstance;
}

export function FxIdWebFeaturesStoreCreatePaymentRequestFromJSON(json: any): FxIdWebFeaturesStoreCreatePaymentRequest {
    return FxIdWebFeaturesStoreCreatePaymentRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesStoreCreatePaymentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesStoreCreatePaymentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'Game': json['Game'],
        'Sku': json['Sku'],
        'Currency': !exists(json, 'Currency') ? undefined : json['Currency'],
        'Country': !exists(json, 'Country') ? undefined : json['Country'],
        'RedirectUrl': !exists(json, 'RedirectUrl') ? undefined : json['RedirectUrl'],
        'WebPublishingPlatform': FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSON(json['WebPublishingPlatform']),
        'VkontaktePlay': !exists(json, 'VkontaktePlay') ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayFromJSON(json['VkontaktePlay']),
        'AbsoluteGames': !exists(json, 'AbsoluteGames') ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesFromJSON(json['AbsoluteGames']),
        'Steam': !exists(json, 'Steam') ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamFromJSON(json['Steam']),
        'EmbeddingType': FxIdApplicationStoreCreatePaymentHandlerEmbeddingTypeFromJSON(json['EmbeddingType']),
    };
}

export function FxIdWebFeaturesStoreCreatePaymentRequestToJSON(value?: FxIdWebFeaturesStoreCreatePaymentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'Game': value.Game,
        'Sku': value.Sku,
        'Currency': value.Currency,
        'Country': value.Country,
        'RedirectUrl': value.RedirectUrl,
        'WebPublishingPlatform': FxIdDomainStoreEnumsSupportedWebPublishingPlatformToJSON(value.WebPublishingPlatform),
        'VkontaktePlay': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestVkontaktePlayToJSON(value.VkontaktePlay),
        'AbsoluteGames': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestAbsoluteGamesToJSON(value.AbsoluteGames),
        'Steam': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentRequestSteamToJSON(value.Steam),
        'EmbeddingType': FxIdApplicationStoreCreatePaymentHandlerEmbeddingTypeToJSON(value.EmbeddingType),
    };
}

