/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestEgsLogin } from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestEgsLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestEgsLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestEgsLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestEgsLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestEgsLogin';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLogin } from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLogin';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestGoogleLogin } from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestGoogleLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestGoogleLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestGoogleLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestGoogleLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestGoogleLogin';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamLogin } from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamLogin';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamOpenIdLogin } from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamOpenIdLogin';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamOpenIdLoginFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamOpenIdLoginFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamOpenIdLoginToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamOpenIdLogin';
import type { FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationType } from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationType';
import {
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationTypeFromJSON,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationTypeFromJSONTyped,
    FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationTypeToJSON,
} from './FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationType';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequest
 */
export interface FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequest {
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationType}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequest
     */
    AuthenticationType: FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationType;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequest
     */
    Game: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequest
     */
    Locale?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequest
     */
    SessionId?: string | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequest
     */
    SteamLogin?: FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamOpenIdLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequest
     */
    SteamOpenIdLogin?: FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamOpenIdLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestEgsLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequest
     */
    EgsLogin?: FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestEgsLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestGoogleLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequest
     */
    GoogleLogin?: FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestGoogleLogin | null;
    /**
     * 
     * @type {FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLogin}
     * @memberof FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequest
     */
    FacebookLogin?: FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLogin | null;
}

/**
 * Check if a given object implements the FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequest interface.
 */
export function instanceOfFxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "AuthenticationType" in value;
    isInstance = isInstance && "Game" in value;

    return isInstance;
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequestFromJSON(json: any): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequest {
    return FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequestFromJSONTyped(json, false);
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AuthenticationType': FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationTypeFromJSON(json['AuthenticationType']),
        'Game': json['Game'],
        'Locale': !exists(json, 'Locale') ? undefined : json['Locale'],
        'SessionId': !exists(json, 'SessionId') ? undefined : json['SessionId'],
        'SteamLogin': !exists(json, 'SteamLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamLoginFromJSON(json['SteamLogin']),
        'SteamOpenIdLogin': !exists(json, 'SteamOpenIdLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamOpenIdLoginFromJSON(json['SteamOpenIdLogin']),
        'EgsLogin': !exists(json, 'EgsLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestEgsLoginFromJSON(json['EgsLogin']),
        'GoogleLogin': !exists(json, 'GoogleLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestGoogleLoginFromJSON(json['GoogleLogin']),
        'FacebookLogin': !exists(json, 'FacebookLogin') ? undefined : FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLoginFromJSON(json['FacebookLogin']),
    };
}

export function FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequestToJSON(value?: FxIdWebFeaturesAuthAuthenticateToFxIdAdapterForExternalIdRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AuthenticationType': FxIdApplicationHandlersAuthAuthenticateToFxIdFxIdAuthenticationTypeToJSON(value.AuthenticationType),
        'Game': value.Game,
        'Locale': value.Locale,
        'SessionId': value.SessionId,
        'SteamLogin': FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamLoginToJSON(value.SteamLogin),
        'SteamOpenIdLogin': FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestSteamOpenIdLoginToJSON(value.SteamOpenIdLogin),
        'EgsLogin': FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestEgsLoginToJSON(value.EgsLogin),
        'GoogleLogin': FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestGoogleLoginToJSON(value.GoogleLogin),
        'FacebookLogin': FxIdApplicationHandlersAuthAuthenticateToFxIdAuthenticateToFxIdRequestFacebookLoginToJSON(value.FacebookLogin),
    };
}

