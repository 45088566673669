import {
	FxIdSdkBaseAdapter,
	IExportedFxIdSdkMethod,
	IFxIdAddToFavoritesRequest,
	IFxIdAddToFavoritesResult,
	IFxIdJoinCommunityRequest,
	IFxIdJoinCommunityResult,
	IFxIdSdkAdapterBuyProductResponse,
	IFxIdSdkAdapterChangeLanguageRequest,
	IFxIdSdkAdapterConsentManagerStatus,
	IFxIdSdkAdapterEmptyDto,
	IFxIdSdkAdapterInfo,
	IFxIdSdkAdapterInviteFriendsRequest,
	IFxIdSdkAdapterOpenModalRequestDto,
	IFxIdSdkAdapterOpenModalResultDto,
	IFxIdSdkAdapterPushTokenResponseDto,
	IFxIdSdkAdapterSocialSettings,
	IFxIdSdkAdapterStatEventRequest,
	IFxIdSdkAdapterStatInitializeRequest,
	IFxIdSdkAdapterStickyBannerShowResultDto,
	IFxIdSdkAuthenticateApiResult,
	IFxIdSdkAuthenticateUser,
	IFxIdSdkGetFriendsResult,
	IFxIdSdkGetFriendsResultForSdk,
	IFxIdSdkGetUrlHashDto,
	IFxIdSdkInviteFriendsResult,
	IFxIdSdkOnAccountChangedEvent,
	IFxIdSdkOnChangeLanguageEvent,
	IFxIdSdkOnChangeUserInfoEvent,
	IFxIdSdkOnFocusChangedEvent,
	IFxIdSdkSendFxEventResult,
	IFxIdSdkSetStatusDto,
	IFxIdSdkSetUrlHashDto,
	IFxIdSdkShowLinkShareModalDto,
	IFxIdSdkWordsFilterRequest,
	IFxIdSendCrazyGamesSdkRequest,
	IFxIdSendVkBridgeRequest,
	ISafeArea
} from "./FxIdSdkBaseAdapter";
import { FxIdSdkAdapterForOk } from "./FxIdSdkAdapterForOk";
import OpenApiClient from "../Api/OpenApiClient";
import {
	FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType,
	FxIdDomainSettingsPortalPublicWebConfigOptions,
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesAuthAuthenticateFromSocialRequest,
	FxIdWebFeaturesAuthAuthenticateFromSocialResponse,
	FxIdWebFeaturesFriendsGetFriendsResponse,
	FxIdWebFeaturesGamesChooseGamePortalConfig,
	FxIdWebFeaturesPlayGamePublicConfigResponse,
	FxIdWebFeaturesStoreStoreDataResponse
} from "../Api/gen";
import { FxIdSdkAdapterForFxId } from "./FxIdSdkAdapterForFxId";
import { FxIdSdkAdapterForVkPlay } from "./FxIdSdkAdapterForVkPlay";
import { FxIdSdkAdapterForVkCom } from "./FxIdSdkAdapterForVkCom";
import { FxIdSdkAdapterForYandexGames } from "./FxIdSdkAdapterForYandexGames";
import { SimpleEventDispatcher } from "strongly-typed-events";
import {
	EventAdsFailed,
	EventAdsFinished,
	EventAdsSkipped,
	EventAdsVideoAvailable,
	EventAdsInterstitialAvailable,
	EventChangeLanguage,
	EventConsentManagerStatus,
	EventCreateSupportRequest,
	EventFocusChanged,
	EventOnChangeOrientation,
	EventOnChangeUserInfo,
	FxIdMessage,
	MessageFxIdInitializePort,
	MessageFxIdPort,
	MethodAddToFavorites,
	MethodAdsIsVideoReady,
	MethodAdsShowVideo,
	MethodAdsIsInterstitialReady,
	MethodAdsShowInterstitial,
	MethodAdsStickyBannerHide,
	MethodAdsStickyBannerShow,
	MethodAuthenticateUser,
	MethodBuyProduct,
	MethodChangeLanguage,
	MethodConsentManagerStatus,
	MethodDefaultStoreFront,
	MethodGetFriends,
	MethodGetInfo,
	MethodGetUrlHash,
	MethodGoogleTagManagerPushToDataLayer,
	MethodInviteFriends,
	MethodJoinCommunity,
	MethodOpenModal,
	MethodPushRegister,
	MethodSendCrazyGamesSdk,
	MethodSendVkBridge,
	MethodSetStatus,
	MethodSetUrlHash,
	MethodShowLinkShareModal,
	MethodSocialSettings,
	MethodStatEvent,
	MethodStatInitialize,
	MethodWordsFilter,
	MethodLoadingReady,
	MethodGameStarted,
	MethodGameStopped
} from "./FxIdMessage";
import { FxIdSdkAdapterForAbsoluteGames } from "./FxIdSdkAdapterForAbsoluteGames";
import {
	getPushSubscribtion,
	openIframeModal,
	openLinkShareModal,
	openLoginWithFirebaseModal,
	openPushPermissionModal,
	openReportIssueModal,
	subscribeToPushes
} from "../Components/Modals";
import { deferred, delayPromise } from "./FxIdSdkUtils";
import { SupportedPlatformToIframeTypeMap, useIsMobile } from "../Screens/WebPlayer/Hooks";
import { useOs } from "@mantine/hooks";
import { signInWithProvider } from "../Utils/Functions/signInWithProvider";
import { getAuthToken, setAuthToken } from "../Api/Auth";
import { getQueriedGamePublicConfig, getQueriedProfileData } from "../Api/Queries";
import { getI18n } from "react-i18next";
import * as Analytics from "../Utils/Analytics";
import { getPushServerKey } from "../Config";
import { getDocUrl, getSiteDeploymentStatus } from "../Utils/Functions";
import { FxIdSdkAdapterForKongregate } from "./FxIdSdkAdapterForKongregate";
import i18next from "i18next";
import _ from "lodash";
import { queryClient } from "../App";
import { FxIdSdkAdapterForFacebookGames } from "./FxIdSdkAdapterForFacebookGames";
import { FxIdSdkAdapterForCrazyGames } from "./FxIdSdkAdapterForCrazyGames";
import i18n, { defaultLocale } from "../i18n";
import { FxIdSdkAdapterForWizQ } from "./FxIdSdkAdapterForWizQ";
import { FxIdSdkAdapterForTelegram } from "./FxIdSdkAdapterForTelegram";
import { FxIdEventProcessor } from "./FxIdEvents/FxIdEventProcessor";

export class FxIdSdk implements IExportedFxIdSdkMethod {
	private socialAdapter?: FxIdSdkBaseAdapter;
	private fxEventsProcessor?: FxIdEventProcessor;
	private config?: FxIdWebFeaturesPlayGamePublicConfigResponse;
	private _lastAuthResponse?: FxIdWebFeaturesAuthAuthenticateFromSocialResponse;

	private _onChangeAccount = new SimpleEventDispatcher<IFxIdSdkOnAccountChangedEvent>();
	private _onChangeUserInfo = new SimpleEventDispatcher<IFxIdSdkOnChangeUserInfoEvent>();

	private _onChangeLanguage = new SimpleEventDispatcher<IFxIdSdkOnChangeLanguageEvent>();

	private _onAdsFinished = new SimpleEventDispatcher<unknown>();
	private _onAdsFailed = new SimpleEventDispatcher<unknown>();
	private _onAdsSkipped = new SimpleEventDispatcher<unknown>();
	private _onAdsVideoAvailable = new SimpleEventDispatcher<unknown>();

	private _onAdsInterstitialAvailable = new SimpleEventDispatcher<unknown>();

	private _onFocusChanged = new SimpleEventDispatcher<unknown>();

	private _onConsentManagerStatus = new SimpleEventDispatcher<IFxIdSdkAdapterConsentManagerStatus>();

	private _onCreateSupportRequest = new SimpleEventDispatcher<(value: PromiseLike<string>) => void>();

	private _onPushToken = new SimpleEventDispatcher<IFxIdSdkAdapterPushTokenResponseDto>();
	private _onDisableKeyboardInputCapture = new SimpleEventDispatcher<IFxIdSdkAdapterEmptyDto>();
	private _onEnableKeyboardInputCapture = new SimpleEventDispatcher<IFxIdSdkAdapterEmptyDto>();

	private _messageChannel: MessageChannel;
	private _iframeWindow: WindowProxy | undefined;

	// HACK: Это просто говнокод, т.к. у меня нет времени, что бы сделать и протестировать нормальную очередь
	// TODO: Надо оставить но не ждать этот флаг, а записывать собыитя в очередь и слать когда все готово
	private _adapterStatInitialized = false;

	private _messageHandlers = new Map<FxIdMessage["method"], (message: FxIdMessage) => Promise<unknown>>([
		[MethodGetInfo, () => this.GetInfo()],
		[MethodSocialSettings, () => this.SocialSettings()],
		[MethodBuyProduct, (message) => this.BuyProduct(...(message.params as Parameters<typeof this.BuyProduct>))],
		[
			MethodDefaultStoreFront,
			(message) => this.DefaultStoreFront(...(message.params as Parameters<typeof this.DefaultStoreFront>))
		],
		[MethodOpenModal, (message) => this.OpenModal(...(message.params as Parameters<typeof this.OpenModal>))],
		[
			MethodAuthenticateUser,
			(message) => this.AuthenticateUser(...(message.params as Parameters<typeof this.AuthenticateUser>))
		],
		[
			MethodPushRegister,
			(message) => this.PushRegister(...(message.params as Parameters<typeof this.PushRegister>))
		],
		[MethodGetUrlHash, () => this.GetUrlHash()],
		[
			MethodSetUrlHash,
			async (message) => {
				await this.SetUrlHash(...(message.params as Parameters<typeof this.SetUrlHash>));
				return this.GetUrlHash();
			}
		],
		[
			MethodShowLinkShareModal,
			(message) => this.ShowLinkShareModal(...(message.params as Parameters<typeof this.ShowLinkShareModal>))
		],
		[MethodSetStatus, (message) => this.SetStatus(...(message.params as Parameters<typeof this.SetStatus>))],
		[
			MethodAdsIsVideoReady,
			async (message) => {
				return await this.AdsIsVideoReady(...(message.params as Parameters<typeof this.AdsIsVideoReady>));
			}
		],
		[
			MethodAdsIsInterstitialReady,
			async (message) => {
				return await this.AdsIsInterstitialReady(
					...(message.params as Parameters<typeof this.AdsIsInterstitialReady>)
				);
			}
		],
		[
			MethodAdsShowVideo,
			async (message) => {
				return await this.AdsShowVideo(...(message.params as Parameters<typeof this.AdsShowVideo>));
			}
		],
		[
			MethodAdsShowInterstitial,
			async (message) => {
				return await this.AdsShowInterstitial(
					...(message.params as Parameters<typeof this.AdsShowInterstitial>)
				);
			}
		],
		[
			MethodAdsStickyBannerShow,
			async (message) => {
				return await this.AdsStickyBannerShow(
					...(message.params as Parameters<typeof this.AdsStickyBannerShow>)
				);
			}
		],
		[
			MethodAdsStickyBannerHide,
			async (message) => {
				return await this.AdsStickyBannerHide(
					...(message.params as Parameters<typeof this.AdsStickyBannerHide>)
				);
			}
		],
		[
			MethodStatInitialize,
			async (message) => {
				return await this.StatInitialize(...(message.params as Parameters<typeof this.StatInitialize>));
			}
		],
		[
			MethodStatEvent,
			async (message) => {
				return await this.StatEvent(...(message.params as Parameters<typeof this.StatEvent>));
			}
		],
		[
			MethodGetFriends,
			async (message) => {
				return await this.GetFriends(...(message.params as Parameters<typeof this.GetFriends>));
			}
		],
		[
			MethodInviteFriends,
			async (message) => {
				return await this.InviteFriends(...(message.params as Parameters<typeof this.InviteFriends>));
			}
		],
		[
			MethodChangeLanguage,
			async (message) => {
				return await this.ChangeLanguage(...(message.params as Parameters<typeof this.ChangeLanguage>));
			}
		],
		[
			MethodJoinCommunity,
			async (message) => {
				return await this.JoinCommunity(...(message.params as Parameters<typeof this.JoinCommunity>));
			}
		],
		[
			MethodAddToFavorites,
			async (message) => {
				return await this.AddToFavorites(...(message.params as Parameters<typeof this.AddToFavorites>));
			}
		],
		[
			MethodGoogleTagManagerPushToDataLayer,
			async (message) => {
				log.info("Received gtag message: %o", message);

				const msg = message.params[0] as string;
				await this.GoogleTagManagerPushToDataLayer(JSON.parse(msg));
				return Promise.resolve();
			}
		],
		[
			MethodSendVkBridge,
			async (message) => {
				return await this.SendVkBridge(...(message.params as Parameters<typeof this.SendVkBridge>));
			}
		],
		[
			MethodSendCrazyGamesSdk,
			async (message) => {
				return await this.SendCrazyGamesSdk(...(message.params as Parameters<typeof this.SendCrazyGamesSdk>));
			}
		],
		[
			MethodWordsFilter,
			async (message) => {
				return await this.WordsFilter(...(message.params as Parameters<typeof this.WordsFilter>));
			}
		],
		[
			MethodConsentManagerStatus,
			async (message) => {
				return await this.ConsentManagerStatus();
			}
		],
		[MethodLoadingReady, () => this.LoadingReady()],
		[MethodGameStarted, () => this.GameStarted()],
		[MethodGameStopped, () => this.GameStopped()]
	]);

	get MessageChannel(): MessageChannel {
		return this._messageChannel;
	}

	public get OnChangeLanguage() {
		return this._onChangeLanguage.asEvent();
	}

	public get OnChangeAccount() {
		return this._onChangeAccount.asEvent();
	}

	public get OnChangeUserInfo() {
		return this._onChangeUserInfo.asEvent();
	}

	public get OnAdsFinished() {
		return this._onAdsFinished.asEvent();
	}

	public get OnAdsFailed() {
		return this._onAdsFailed.asEvent();
	}

	public get OnAdsSkipped() {
		return this._onAdsSkipped.asEvent();
	}

	public get OnAdsVideoAvailable() {
		return this._onAdsVideoAvailable.asEvent();
	}

	public get OnAdsInterstitialAvailable() {
		return this._onAdsInterstitialAvailable.asEvent();
	}

	public get OnFocusChanged() {
		return this._onFocusChanged.asEvent();
	}

	// NOTE: Это хак для версии гц от 2024-05 - надо будет удалить. НЕ ИСПОЛЬЗОВАТЬ
	public get OnFocusChange() {
		return this._onFocusChanged.asEvent();
	}

	public DispatchAdsVideoAvailable() {
		this._onAdsVideoAvailable.dispatch({});
		this.IframeMessageEventAdsVideoAvailable();
	}

	public DispatchConsentManagerStatus(status: IFxIdSdkAdapterConsentManagerStatus) {
		this._onConsentManagerStatus.dispatch(status);
		this.IframeMessageEventConsentManagerStatus(status);
	}

	private IframeMessageEventAdsVideoAvailable() {
		const message: FxIdMessage = {
			method: EventAdsVideoAvailable,
			params: []
		};
		void this._messageChannel.port1.postMessage(message);
	}

	private IframeMessageEventConsentManagerStatus(status: IFxIdSdkAdapterConsentManagerStatus) {
		const message: FxIdMessage = {
			method: EventConsentManagerStatus,
			params: [],
			result: status
		};
		void this._messageChannel.port1.postMessage(message);
	}

	public DispatchAdsInterstitialAvailable() {
		this._onAdsInterstitialAvailable.dispatch({});
		this.IframeMessageEventAdsInterstitialAvailable();
	}

	private IframeMessageEventAdsInterstitialAvailable() {
		const message: FxIdMessage = {
			method: EventAdsInterstitialAvailable,
			params: []
		};
		void this._messageChannel.port1.postMessage(message);
	}

	public DispatchAdsFinished() {
		this._onAdsFinished.dispatch({});
		this.IframeMessageEventAdsFinished();
	}

	private IframeMessageEventAdsFinished() {
		const message: FxIdMessage = {
			method: EventAdsFinished,
			params: []
		};
		void this._messageChannel.port1.postMessage(message);
	}

	public DispatchAdsFailed() {
		this._onAdsFailed.dispatch({});
		this.IframeMessageEventAdsFailed();
	}

	private IframeMessageEventAdsFailed() {
		const message: FxIdMessage = {
			method: EventAdsFailed,
			params: []
		};
		void this._messageChannel.port1.postMessage(message);
	}

	public DispatchAdsSkipped() {
		this._onAdsSkipped.dispatch({});
		this.IframeMessageEventAdsSkipped();
	}

	private IframeMessageEventAdsSkipped() {
		const message: FxIdMessage = {
			method: EventAdsSkipped,
			params: []
		};
		void this._messageChannel.port1.postMessage(message);
	}

	public DispatchFocusChanged(data: IFxIdSdkOnFocusChangedEvent): void {
		this._onFocusChanged.dispatch(data);
		this.IframeMessageEventFocusChanged();
	}

	private IframeMessageEventFocusChanged() {
		const message: FxIdMessage = {
			method: EventFocusChanged,
			params: []
		};
		void this._messageChannel.port1.postMessage(message);
	}

	private DispatchOnChangedUserInfo(changeInfoEvent: IFxIdSdkOnChangeUserInfoEvent) {
		log.info("Dispatching onChangeUserInfo event: %o", changeInfoEvent);
		this._onChangeUserInfo.dispatch(changeInfoEvent);
		this.IframeMessageEventOnChangeUserInfo(changeInfoEvent);
	}

	private IframeMessageEventOnChangeUserInfo(changeInfoEvent: IFxIdSdkOnChangeUserInfoEvent) {
		const message: FxIdMessage = {
			method: EventOnChangeUserInfo,
			params: [],
			result: changeInfoEvent
		};
		void this._messageChannel.port1.postMessage(message);
	}

	public get OnCreateSupportRequest() {
		return this._onCreateSupportRequest.asEvent();
	}

	public get OnPushToken() {
		return this._onPushToken.asEvent();
	}

	public get OnDisableKeyboardInputCapture() {
		return this._onDisableKeyboardInputCapture.asEvent();
	}

	public get OnEnableKeyboardInputCapture() {
		return this._onEnableKeyboardInputCapture.asEvent();
	}

	public DispatchDisableKeyboardInputCapture() {
		return this._onDisableKeyboardInputCapture.dispatch({});
	}

	public DispatchEnableKeyboardInputCapture() {
		return this._onEnableKeyboardInputCapture.dispatch({});
	}

	public get Adapter(): FxIdDomainStoreEnumsSupportedWebPublishingPlatform {
		return this.adapter;
	}

	constructor(
		protected game: string,
		protected adapter: FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
		protected portalConfig: FxIdWebFeaturesGamesChooseGamePortalConfig
	) {
		this._messageChannel = new MessageChannel();
		this._messageChannel.port1.onmessage = this.SubscribeToChannel.bind(this);
		log.info("Created message channel");

		this.SubscribeToScreenOrientationChange();
	}

	public RegisterIframeWindow(iframeWindow: WindowProxy) {
		if (this._iframeWindow != null) {
			log.error("IFrame window already initialized. Releasing old initialization and saving new");
			this._iframeWindow = undefined;
			// Т.к. предыдущий messageChannel уже ушел в старый айфрейм и использовать его больше нельзя (он transfered)
			// то над создать новый
			this._messageChannel = new MessageChannel();
			this._messageChannel.port1.onmessage = this.SubscribeToChannel.bind(this);
		}

		log.info("Registering iframe %o", iframeWindow);
		this._iframeWindow = iframeWindow;
		log.info("Starting to listen for %s message", MessageFxIdInitializePort);
		window.addEventListener("message", this.OnWindowPostMessage.bind(this));
	}

	private portTransferred = false;

	private OnWindowPostMessage(eventMessage: MessageEvent) {
		if (eventMessage.data === MessageFxIdInitializePort) {
			log.info("Received %s message. Sending port to iframe window", MessageFxIdInitializePort);

			if (this.portTransferred) {
				log.warn("Port already transferred");
			}

			if (this._iframeWindow == null) {
				throw new Error(`Received ${MessageFxIdInitializePort} but iframeWindow not initialized!`);
			}
			this._iframeWindow!.postMessage(MessageFxIdPort, "*", [this._messageChannel.port2]);
			this.portTransferred = true; // Mark the port as transferred
		}
	}

	async InitializeSocialAdapter(): Promise<void> {
		if (this.socialAdapter != null) {
			throw new Error("Already initialized");
		}

		this.config = await getQueriedGamePublicConfig({ gameId: this.game, platform: this.adapter });

		this.InitializeFxEvents();

		if (this.adapter === FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Odnoklassniki) {
			this.socialAdapter = new FxIdSdkAdapterForOk(this, this.game, this.config);
		} else if (this.adapter === FxIdDomainStoreEnumsSupportedWebPublishingPlatform.VkontaktePlay) {
			this.socialAdapter = new FxIdSdkAdapterForVkPlay(this, this.game, this.config);
		} else if (this.adapter === FxIdDomainStoreEnumsSupportedWebPublishingPlatform.VkontakteCom) {
			this.socialAdapter = new FxIdSdkAdapterForVkCom(this, this.game, this.config);
		} else if (this.adapter === FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FxId) {
			this.socialAdapter = new FxIdSdkAdapterForFxId(this, this.game, this.config);
		} else if (this.adapter === FxIdDomainStoreEnumsSupportedWebPublishingPlatform.YandexGames) {
			this.socialAdapter = new FxIdSdkAdapterForYandexGames(this, this.game, this.config);
		} else if (this.adapter === FxIdDomainStoreEnumsSupportedWebPublishingPlatform.AbsoluteGames) {
			this.socialAdapter = new FxIdSdkAdapterForAbsoluteGames(this, this.game, this.config);
		} else if (this.adapter === FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Kongregate) {
			this.socialAdapter = new FxIdSdkAdapterForKongregate(this, this.game, this.config);
		} else if (this.adapter === FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FacebookGames) {
			this.socialAdapter = new FxIdSdkAdapterForFacebookGames(this, this.game, this.config);
		} else if (this.adapter === FxIdDomainStoreEnumsSupportedWebPublishingPlatform.CrazyGames) {
			this.socialAdapter = new FxIdSdkAdapterForCrazyGames(this, this.game, this.config);
		} else if (this.adapter === FxIdDomainStoreEnumsSupportedWebPublishingPlatform.WizQ) {
			this.socialAdapter = new FxIdSdkAdapterForWizQ(this, this.game, this.config);
		} else if (this.adapter === FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Telegram) {
			this.socialAdapter = new FxIdSdkAdapterForTelegram(this, this.game, this.config);
		} else {
			throw new Error(`Unsupported adapter for ${this.adapter}`);
		}

		await this.socialAdapter!.Initialize();

		this.StartListenToCMPEvents();
	}

	private InitializeFxEvents() {
		if (this.config?.PublicWebClientConfig?.FxEvents != null) {
			this.fxEventsProcessor = new FxIdEventProcessor(this.config.PublicWebClientConfig.FxEvents);
		}
	}

	private async SubscribeToChannel(eventMessage: MessageEvent<FxIdMessage>) {
		log.info("[MSG CHANNEL] Received message from channel: %o", eventMessage);
		const ev = eventMessage.data;
		try {
			const handler = this._messageHandlers.get(ev.method);
			if (!handler) throw new Error(`[MSG CHANNEL] Unknown method for channel: ${ev.method}`);
			const result = await handler(ev);
			log.info(`[MSG CHANNEL] Result for %s: %o`, ev.method, result);
			this._messageChannel.port1.postMessage({ ...ev, result });
		} catch (error) {
			log.error(
				`[MSG CHANNEL] Error while handling method %s with params %o. Error: %o`,
				ev.method,
				ev.params,
				error
			);
			this._messageChannel.port1.postMessage({ ...ev, error: String(error) });
		}
	}

	async LoadingReady(): Promise<void> {
		return await this.socialAdapter!.LoadingReady();
	}

	async GameStarted(): Promise<void> {
		return await this.socialAdapter!.GameStarted();
	}

	async GameStopped(): Promise<void> {
		return await this.socialAdapter!.GameStopped();
	}

	async GetUrlHash(): Promise<IFxIdSdkGetUrlHashDto> {
		return Promise.resolve({ hash: window.location.hash });
	}

	async SetUrlHash(requestJson: string): Promise<void> {
		const request: IFxIdSdkSetUrlHashDto = JSON.parse(requestJson);

		if (request.hash === "") {
			window.history.replaceState(window.history.state, "", window.location.pathname);
			return Promise.resolve();
		}
		window.history.replaceState(window.history.state, "", `#${request.hash}`);

		return Promise.resolve();
	}

	async GoogleTagManagerPushToDataLayer(obj: any) {
		try {
			const emailKey = Object.keys(obj).find((key) => key.toLowerCase() === "email");
			const email = emailKey ? obj[emailKey] : null;

			if (email) {
				return window.dataLayer?.push(obj);
			} else {
				const profile = await getQueriedProfileData();
				const newObj = {
					...obj,
					email: profile.Email
				};
				return window.dataLayer?.push(newObj);
			}
		} catch (err) {
			log.error("Error while trying to send to gtag", err);
		}
	}

	async SendVkBridge(requestJson: string) {
		if (this.socialAdapter != null && this.socialAdapter instanceof FxIdSdkAdapterForVkCom) {
			const request: IFxIdSendVkBridgeRequest = JSON.parse(requestJson);
			const result = await this.socialAdapter.SendVkBridge(request.method, request.props);

			return result;
		}
	}

	async SendCrazyGamesSdk(requestJson: string) {
		if (this.socialAdapter != null && this.socialAdapter instanceof FxIdSdkAdapterForCrazyGames) {
			const request: IFxIdSendCrazyGamesSdkRequest = JSON.parse(requestJson);
			const result = await this.socialAdapter.SendCrazyGamesSdk(request.method, request.props);

			return result;
		}
	}

	async WordsFilter(requestJson: string) {
		const request = JSON.parse(requestJson) as IFxIdSdkWordsFilterRequest;
		return await this.socialAdapter!.WordsFilter(request);
	}

	async GetInfo(): Promise<IFxIdSdkAdapterInfo> {
		if (this.socialAdapter == null) {
			throw new Error("Not initialized");
		}

		const profile = await getQueriedProfileData();

		const connectedGame = profile.ConnectedGames.find((el) => el.Game.toLowerCase() === this.game.toLowerCase());

		if (connectedGame == null) {
			throw new Error(`Game ${this.game} is not connected to FxId`);
		}

		// eslint-disable-next-line react-hooks/rules-of-hooks
		const os = useOs();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const isMobile = useIsMobile();

		const socialInfo = await this.socialAdapter.GetSocialInfo();

		const safeAreaMargins = this.GetSafeAreaMargins();

		const result: IFxIdSdkAdapterInfo = {
			game: this.game,
			environment: {
				os: os,
				isMobile: isMobile,
				safeArea: safeAreaMargins
			},
			fxId: {
				userId: profile.AccountId.toString(),
				marketingEnabled: profile.MarketingEnabled,
				locale: profile.Locale,
				geoCountry: profile.GeoCountry,
				token: (await getAuthToken())!,
				gameToken: connectedGame.GameJwt,
				gameUid: connectedGame.GameUid,
				guest: profile.Guest,
				hasEmail: profile.HasEmail,
				emailValidated: profile.EmailValidated,
				hasCompletedOrder: profile.HasCompletedOrder
			},
			social: socialInfo
		};

		if (import.meta.env.VITE_APP_ENV_OVERRIDE_NAME !== "prod") {
			log.info("GetInfo result: %o", result);
		}

		return result;
	}

	async SocialSettings(): Promise<IFxIdSdkAdapterSocialSettings> {
		return await this.socialAdapter!.SocialSettings();
	}

	async LoginToFxId(): Promise<void> {
		const request: FxIdWebFeaturesAuthAuthenticateFromSocialRequest = {
			Game: this.game
		} as FxIdWebFeaturesAuthAuthenticateFromSocialRequest;

		switch (this.adapter) {
			case FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FxId: {
				request.Social =
					FxIdDomainStoreEnumsSupportedWebPublishingPlatform[
						FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FxId
					];
				const searchParams = new URL(String(window.location)).searchParams;
				const LoginCode = searchParams.get("loginCode");
				if (LoginCode) {
					log.info("Found loginCode in URL:", LoginCode);
					log.info("Appending loginCode to request");

					request.FxIdLogin = { LoginCode };
				}
				break;
			}
			case FxIdDomainStoreEnumsSupportedWebPublishingPlatform.VkontaktePlay:
				request.Social =
					FxIdDomainStoreEnumsSupportedWebPublishingPlatform[
						FxIdDomainStoreEnumsSupportedWebPublishingPlatform.VkontaktePlay
					];
				request.VkontaktePlayLogin = {
					QueryString: location.search
				};
				break;
			case FxIdDomainStoreEnumsSupportedWebPublishingPlatform.VkontakteCom:
				request.Social =
					FxIdDomainStoreEnumsSupportedWebPublishingPlatform[
						FxIdDomainStoreEnumsSupportedWebPublishingPlatform.VkontakteCom
					];
				request.VkontakteComLogin = {
					QueryString: location.search
				};
				break;

			case FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Odnoklassniki:
				request.Social =
					FxIdDomainStoreEnumsSupportedWebPublishingPlatform[
						FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Odnoklassniki
					];
				request.OdnoklassnikiLogin = {
					QueryString: location.search
				};
				break;

			case FxIdDomainStoreEnumsSupportedWebPublishingPlatform.YandexGames: {
				const yandexPlayerInfo = await (this.socialAdapter as FxIdSdkAdapterForYandexGames).GetPlayer();
				const yandexSocialInfo = await this.socialAdapter!.GetSocialInfo();

				request.Social =
					FxIdDomainStoreEnumsSupportedWebPublishingPlatform[
						FxIdDomainStoreEnumsSupportedWebPublishingPlatform.YandexGames
					];

				request.YandexGamesLogin = {
					GetPlayerSignature: yandexPlayerInfo.signature,
					EnvironmentI18nLang: yandexSocialInfo.socialLocale ?? "ru"
				};
				break;
			}
			case FxIdDomainStoreEnumsSupportedWebPublishingPlatform.AbsoluteGames:
				request.Social =
					FxIdDomainStoreEnumsSupportedWebPublishingPlatform[
						FxIdDomainStoreEnumsSupportedWebPublishingPlatform.AbsoluteGames
					];
				request.AbsoluteGamesLogin = {
					QueryString: location.search
				};
				break;
			case FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Kongregate: {
				const kgPlayerInfo = await (this.socialAdapter as FxIdSdkAdapterForKongregate).GetPlayer();

				request.Social =
					FxIdDomainStoreEnumsSupportedWebPublishingPlatform[
						FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Kongregate
					];
				request.Kongregate = {
					UserId: kgPlayerInfo.userId,
					UserName: kgPlayerInfo.userName,
					Token: kgPlayerInfo.gameAuthToken,
					IsGuest: kgPlayerInfo.isGuest
				};
				break;
			}
			case FxIdDomainStoreEnumsSupportedWebPublishingPlatform.CrazyGames: {
				const cgToken = await (this.socialAdapter as FxIdSdkAdapterForCrazyGames).GetUserToken();

				request.Social =
					FxIdDomainStoreEnumsSupportedWebPublishingPlatform[
						FxIdDomainStoreEnumsSupportedWebPublishingPlatform.CrazyGames
					];
				request.CrazyGames = {
					Token: cgToken ?? "",
					IsGuest: cgToken == null
				};
				break;
			}
			case FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FacebookGames: {
				const fbGamesPlayerInfo = (
					this.socialAdapter as FxIdSdkAdapterForFacebookGames
				).GetLoginStatusResponse();

				if (fbGamesPlayerInfo == null) {
					throw new Error("Facebook is not logged in");
				}

				if (fbGamesPlayerInfo.authResponse.signedRequest == null) {
					throw new Error("Facebook player does not have signedRequest");
				}

				request.Social =
					FxIdDomainStoreEnumsSupportedWebPublishingPlatform[
						FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FacebookGames
					];
				request.FacebookGamesLogin = {
					SignedRequest: fbGamesPlayerInfo.authResponse.signedRequest
				};
				break;
			}
			case FxIdDomainStoreEnumsSupportedWebPublishingPlatform.WizQ: {
				const token = await (this.socialAdapter! as FxIdSdkAdapterForWizQ).GetUserIdToken();

				request.Social =
					FxIdDomainStoreEnumsSupportedWebPublishingPlatform[
						FxIdDomainStoreEnumsSupportedWebPublishingPlatform.WizQ
					];
				request.WizQ = {
					Token: token
				};
				break;
			}
			case FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Telegram: {
				const initData = await (this.socialAdapter! as FxIdSdkAdapterForTelegram).GetInitData();
				request.Social =
					FxIdDomainStoreEnumsSupportedWebPublishingPlatform[
						FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Telegram
					];
				request.Telegram = {
					InitData: initData
				};
			}

			// request.YandexGamesLogin = {
			// 	GetPlayerSignature: yandexPlayerInfo.signature
			// };
		}

		if (request.Social == null) {
			throw new Error(`Unknown social for adapter ${this.adapter}`);
		}

		log.info("Logging in");
		await this.AuthenticateApi(request);
		void Analytics.sdk.logUserInFxId({
			game: this.game,
			sdk_adapter: FxIdDomainStoreEnumsSupportedWebPublishingPlatform[this.adapter]
		});

		await this.socialAdapter!.RegisterShareHandlers();
	}

	private authenticationApiInProgress = false;

	public IsAuthenticationApiInProgress() {
		return this.authenticationApiInProgress;
	}

	async AuthenticateApi(
		request: FxIdWebFeaturesAuthAuthenticateFromSocialRequest
	): Promise<IFxIdSdkAuthenticateApiResult> {
		let changeEvent: IFxIdSdkOnAccountChangedEvent | undefined = undefined;
		let changeInfoEvent: IFxIdSdkOnChangeUserInfoEvent | undefined = undefined;
		let authResponse: FxIdWebFeaturesAuthAuthenticateFromSocialResponse;
		try {
			this.authenticationApiInProgress = true;
			log.info("Authenticating with request: %o. Last response was: %o", request, this._lastAuthResponse);
			const lastAuthResponseAccountId = this._lastAuthResponse?.AccountId;
			authResponse = await OpenApiClient.Auth.fxIdWebFeaturesAuthAuthenticateFromSocialEndpoint(request);
			log.info("Received auth response: %o", authResponse);
			setAuthToken(authResponse.Token);

			this._lastAuthResponse = authResponse;

			if (lastAuthResponseAccountId != null && lastAuthResponseAccountId !== authResponse.AccountId) {
				log.info(
					"Account changed from %s to %s. Sending account changed event",
					lastAuthResponseAccountId,
					authResponse.AccountId
				);

				changeEvent = {
					oldAccountId: lastAuthResponseAccountId,
					newAccountId: authResponse.AccountId
				};
			}

			// Супер говнокод. Надо что бы getProfile работал
			queryClient.clear();
			// // //

			const newInfo = await this.GetInfo();
			changeInfoEvent = { newInfo };
		} finally {
			this.authenticationApiInProgress = false;
		}

		if (changeEvent != null) {
			log.info("Dispatching onChangeAccount event %o", changeEvent);
			this._onChangeAccount.dispatch(changeEvent);
		}

		if (changeInfoEvent != null) {
			this.DispatchOnChangedUserInfo(changeInfoEvent);
		}

		return {
			authResponse,
			changeEvent,
			changeInfoEvent
		};
	}

	async BuyProduct(requestJson: string): Promise<IFxIdSdkAdapterBuyProductResponse> {
		if (this.socialAdapter == null) {
			throw new Error("Not initialized");
		}
		log.info("Buying product %o", requestJson);

		const request = JSON.parse(requestJson);

		void Analytics.sdk.buyProduct({
			game: this.game,
			sdk_adapter: FxIdDomainStoreEnumsSupportedWebPublishingPlatform[this.adapter],
			product_sku: String(request.sku)
		});

		await this.ExitFullscreen();

		return this.socialAdapter.BuyProduct(request);
	}

	async DefaultStoreFront(): Promise<FxIdWebFeaturesStoreStoreDataResponse> {
		log.info("Returning default store front");

		const currency = await this.socialAdapter?.StoreCurrency();
		const storeAdditionalData = await this.socialAdapter?.StoreAdditionalData();

		return OpenApiClient.Store.fxIdWebFeaturesStoreStoreDataEndpoint({
			Game: this.game,
			WebPublishingPlatform: this.adapter,
			Currency: currency,
			Locale: i18next.language,
			StoreAdditionalData: storeAdditionalData
		});
	}

	async StatInitialize(requestJson: string): Promise<{ success: boolean }> {
		log.info("StatInitialize %o", requestJson);

		const request: IFxIdSdkAdapterStatInitializeRequest = JSON.parse(requestJson);
		void Analytics.sdk.gameStart({
			game: this.game,
			sdk_adapter: FxIdDomainStoreEnumsSupportedWebPublishingPlatform[this.adapter]
		});
		await this.socialAdapter!.StatInitialize(request);
		this._adapterStatInitialized = true;

		return { success: true };
	}

	async StatEvent(requestJson: string): Promise<{ success: boolean }> {
		const request: IFxIdSdkAdapterStatEventRequest = JSON.parse(requestJson);

		if (!this._adapterStatInitialized) {
			log.info("Stat is not initialized. Waiting");
			while (!this._adapterStatInitialized) {
				await delayPromise(1000);
			}
			log.info("Stat initialized. Sending event");
		}

		await this.socialAdapter!.StatEvent(request);
		return { success: true };
	}

	async AdsIsVideoReady(requestJson: string): Promise<{ success: boolean }> {
		log.info("AdsIsVideoReady %o", requestJson);

		const result = await this.socialAdapter!.AdsIsVideoReady();

		return { success: result };
	}

	async AdsShowVideo(requestJson: string): Promise<{ success: boolean }> {
		log.info("AdsShowVideo %o", requestJson);
		await this.ExitFullscreen();
		await this.socialAdapter!.AdsShowVideo();
		return { success: true };
	}

	async AdsIsInterstitialReady(requestJson: string): Promise<{ success: boolean }> {
		log.info("AdsIsInterstitialReady %o", requestJson);
		const result = await this.socialAdapter!.AdsIsInterstitialReady();
		return { success: result };
	}

	async AdsShowInterstitial(requestJson: string): Promise<{ success: boolean }> {
		log.info("AdsShowVideo %o", requestJson);
		await this.ExitFullscreen();
		await this.socialAdapter!.AdsShowInterstitial();

		return { success: true };
	}

	async AdsStickyBannerShow(): Promise<IFxIdSdkAdapterStickyBannerShowResultDto> {
		log.info("AdsStickyBannerShow");
		await this.ExitFullscreen();
		return this.socialAdapter!.AdsStickyBannerShow();
	}

	AdsStickyBannerHide(): Promise<IFxIdSdkAdapterStickyBannerShowResultDto> {
		log.info("AdsStickyBannerHide");

		return this.socialAdapter!.AdsStickyBannerHide();
	}

	async OpenModal(requestJson: string): Promise<IFxIdSdkAdapterOpenModalResultDto> {
		log.info("Opening modal %o", requestJson);

		await this.ExitFullscreen();

		const request: IFxIdSdkAdapterOpenModalRequestDto = JSON.parse(requestJson);
		const url = await this.__HACK_RemapUrl(new URL(request.Url));

		if (
			this.adapter !== FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FxId &&
			this.adapter !== FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Unspecified
		) {
			url.searchParams.append("social", FxIdDomainStoreEnumsSupportedWebPublishingPlatform[this.adapter]);
		}

		openIframeModal({ url: url.toString() });
		void Analytics.sdk.openIframeModal({
			game: this.game,
			sdk_adapter: FxIdDomainStoreEnumsSupportedWebPublishingPlatform[this.adapter],
			url: url.toString()
		});

		return Promise.resolve({ Success: true });
	}

	async AuthenticateUser(requestJson: string): Promise<void> {
		if (this.adapter !== FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FxId) {
			log.info("Will authenticate user using this adapter", this.adapter);
			await this.socialAdapter?.AuthenticateUser({});
			return;
		}

		log.info("Authenticating user %o", requestJson);

		const request: IFxIdSdkAuthenticateUser = JSON.parse(requestJson);

		if (request.import !== undefined) {
			// Сначала парсим SocialIdentifierType. Он должен быть строкой типа keyof typeof FxIdDomainModelsDBSocialIdentifierSocialIdentifierType
			const SocialIdentifierType =
				FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType[
					request.import
						.socialIdentifierType as keyof typeof FxIdDomainModelsDBDBSocialIdentifierSocialIdentifierType
				];
			if (SocialIdentifierType === undefined) throw new Error("[AUTH IMPORT] Incorrect social identifier type");
			if (!this.config) throw new Error("[AUTH IMPORT] No game config present");

			try {
				const authResponse = await OpenApiClient.Auth.fxIdWebFeaturesAuthAuthenticateFromImportEndpoint({
					SocialIdentifier: request.import.socialIdentifier,
					SocialIdentifierType,
					GameSystemName: this.config?.GameSystemName
				});
				setAuthToken(authResponse.Token);
				return window.location.reload();
			} catch (error) {
				log.error("[AUTH IMPORT] Error while authenticating user. ", error);
				return;
			}
		}

		await this.ExitFullscreen();

		if (request.force == null) {
			openLoginWithFirebaseModal({
				onAuthSuccessful: () => window.location.reload(),
				authProviders: {
					allowed:
						request.preferredSocials ??
						this.config?.PublicWebClientConfig?.Auth?.AllowedAuthProviders ??
						this.portalConfig.PortalPublicWebConfig.CompanyBranding.Auth.AllowedAuthProviders ??
						[],
					preferred: request.preferredSocials
				},
				analyticsData: {
					game: this.game,
					sdk_adapter: FxIdDomainStoreEnumsSupportedWebPublishingPlatform[this.adapter]
				},
				gameConfig: this.config,
				portalConfig: this.portalConfig,
				adapter: SupportedPlatformToIframeTypeMap[this.adapter]
			});
		} else {
			const publicConfig = this.config;
			if (!publicConfig) {
				log.error("Attemted forced authentication with no GameConfig");
				return;
			}
			await signInWithProvider(request.force.providerId, publicConfig);
			void Analytics.sdk.forceUserAuthentication({
				provider_id: request.force.providerId,
				game: this.game,
				sdk_adapter: FxIdDomainStoreEnumsSupportedWebPublishingPlatform[this.adapter]
			});
			window.location.reload();
		}

		return;
	}

	async ChangeLanguage(requestJson: string): Promise<void> {
		log.info("Changing language to %s", requestJson);

		const langData: IFxIdSdkAdapterChangeLanguageRequest = JSON.parse(requestJson);
		const lang = langData.lang;

		try {
			await i18n.changeLanguage(lang);
		} catch (e) {
			log.error(e);
			log.warn("Can't change language to %s. Supported languages: %o", lang, i18n.languages);
			return;
		}

		void Analytics.sdk.changeLanguage({
			game: this.game,
			sdk_adapter: FxIdDomainStoreEnumsSupportedWebPublishingPlatform[this.adapter],
			new_lang: lang,
			old_lang: getI18n().resolvedLanguage ?? defaultLocale
		});

		const args = { language: lang };

		this._onChangeLanguage.dispatch(args);
		this.IframeMessageEventChangeLanguage(lang, args);

		return;
	}

	private IframeMessageEventChangeLanguage(lang: string, args: { language: string }) {
		const message: FxIdMessage = {
			method: EventChangeLanguage,
			params: [lang],
			result: args
		};
		this._messageChannel.port1.postMessage(message);
	}

	private IframeMessageEventOnChangeOrientation(args: {
		orientationType: OrientationType;
		orientationAngle: number;
		orientation: "landscape" | "portrait";
		safeArea: ISafeArea;
	}) {
		const message: FxIdMessage = {
			method: EventOnChangeOrientation,
			params: [],
			result: args
		};
		log.info("IframeMessageEventOnChangeOrientation...");
		log.info("отправляемый объект: ", message);

		this._messageChannel.port1.postMessage(message);
	}

	async CreateSupportRequest(): Promise<void> {
		log.info("Received support request from game");

		const { promise, resolve, reject } = deferred<string>();

		this._onCreateSupportRequest.dispatch(resolve);
		await this.IframeMessageEventCreateSupportRequest();

		const eventResult = await promise;

		// const profile = await getQueriedProfileData();

		// const supportData = { ...supportRequest, game: JSON.parse(eventResult) };

		log.info("Received result from support event: %o", eventResult);

		await this.ExitFullscreen();

		// TODO: Настроить нормальную передачу данных из игры в открывающуюся модалку
		openReportIssueModal({ gameData: eventResult });
		void Analytics.sdk.reportIssue({
			game: this.game,
			sdk_adapter: FxIdDomainStoreEnumsSupportedWebPublishingPlatform[this.adapter]
		});

		return Promise.resolve();
	}

	private IframeMessageEventCreateSupportRequest() {
		const message: FxIdMessage = {
			method: EventCreateSupportRequest,
			params: []
		};
		this._messageChannel.port1.postMessage(message);
		return Promise.resolve();
	}

	async PushRegister(): Promise<IFxIdSdkAdapterPushTokenResponseDto> {
		const { isIframe } = getSiteDeploymentStatus();
		if (isIframe) {
			log.warn("[PUSH] We're in iframe. Skipping registration");
			return Promise.resolve({});
		}
		// Считаем, что если браузер не поддерживает уведомления, доступ к ним отказан
		const permission = window?.Notification.permission ?? "denied";
		if (permission === "denied") {
			log.info("[PUSH] Permission already denied. Skipping registration");
			return Promise.resolve({});
		}
		const { success, subscription: activeSubscription } = await getPushSubscribtion();
		if (!success) {
			log.warn("[PUSH] Getting subscription unsuccessful. Skipping registration");
			return Promise.resolve({});
		}
		if (activeSubscription) {
			log.info("[PUSH] Active push subscription present", activeSubscription);
			return Promise.resolve({ Token: JSON.stringify(activeSubscription) });
		}
		if (permission === "granted") {
			log.info("[PUSH] Permission already granted, but no active subscription present. Silently subscribing");
			const subscription = await subscribeToPushes(getPushServerKey());
			return Promise.resolve(subscription ? { Token: JSON.stringify(subscription) } : {});
		}

		const { t } = getI18n();
		await this.ExitFullscreen();

		return new Promise((resolve) => {
			openPushPermissionModal({
				serverKey: getPushServerKey(),
				onPushSubscriptionFail: () => resolve({}),
				onPushSubscriptionSuccess: (subscription) => resolve({ Token: JSON.stringify(subscription) }),
				gameName: t(`game.${this.game}`)
			});
		});
		// return Promise.resolve<IFxIdSdkAdapterPushTokenResponseDto>({});
	}

	async GetFriends(requestJson: string): Promise<IFxIdSdkGetFriendsResultForSdk> {
		const request = JSON.parse(requestJson);
		const socialFriends = await this.socialAdapter!.GetFriends(request);
		const fxFriends = await OpenApiClient.Friends.fxIdWebFeaturesFriendsGetFriendsEndpoint({
			FriendsIds: socialFriends.friends.map((f) => f.uid)
		});

		return {
			socialFriends: socialFriends.friends,
			Friends: fxFriends.Friends
		};
	}

	async InviteFriends(requestJson: string): Promise<IFxIdSdkInviteFriendsResult> {
		const request = JSON.parse(requestJson) as IFxIdSdkAdapterInviteFriendsRequest;
		await this.ExitFullscreen();
		return await this.socialAdapter!.InviteFriends(request);
	}

	async JoinCommunity(requestJson: string): Promise<IFxIdJoinCommunityResult> {
		const request = JSON.parse(requestJson) as IFxIdJoinCommunityRequest;
		await this.ExitFullscreen();
		return await this.socialAdapter!.JoinCommunity(request);
	}

	async AddToFavorites(requestJson: string): Promise<IFxIdAddToFavoritesResult> {
		const request = JSON.parse(requestJson) as IFxIdAddToFavoritesRequest;
		await this.ExitFullscreen();
		return await this.socialAdapter!.AddToFavorites(request);
	}

	async ShowLinkShareModal(requestJson: string) {
		const request: IFxIdSdkShowLinkShareModalDto = JSON.parse(requestJson);
		await this.ExitFullscreen();
		openLinkShareModal({ link: request.Link });
		return Promise.resolve();
	}

	async SetStatus(requestJson: string): Promise<void> {
		const request: IFxIdSdkSetStatusDto = JSON.parse(requestJson);
		await OpenApiClient.Friends.fxIdWebFeaturesFriendsSetStatusEndpoint({
			Name: request.name,
			Priority: request.priority,
			Status: request.status
		});
	}

	private async __HACK_RemapUrl(url: URL) {
		if (import.meta.env.VITE_APP_TENANT_OVERRIDE === "" || import.meta.env.VITE_APP_TENANT_OVERRIDE === "FX") {
			if (
				url.toString().startsWith("https://fxgames.zendesk.com/hc") ||
				url.toString().match(/\/docs\/(.*)\/faq.html/)
			) {
				// log.info("Remapping to freshdesk");
				// return new URL("https://fxgames.freshdesk.com/support/solutions");
				log.info("Remapping to faq.txt");
				const docUrl = await getDocUrl({
					docFile: "faq.html",
					adapter: SupportedPlatformToIframeTypeMap[this.adapter]
				});
				return docUrl;
			}
			if (url.toString().startsWith("https://discord")) {
				log.info("Remapping discrod url");
				const url = new URL(`${import.meta.env.BASE_URL}/discord-qr`, window.location.origin);
				url.searchParams.append("game", this.game);
				return url;
			}
			if (url.toString().startsWith("https://fx.gl/terms-of-service")) {
				log.info("Remapping tos url");
				const docUrl = await getDocUrl({
					docFile: "tos.txt",
					adapter: SupportedPlatformToIframeTypeMap[this.adapter]
				});
				return docUrl;
			}
			if (url.toString().startsWith("https://fx.gl/privacy-policy")) {
				log.info("Remapping pp url");
				const docUrl = await getDocUrl({
					docFile: "pp.txt",
					adapter: SupportedPlatformToIframeTypeMap[this.adapter]
				});
				return docUrl;
			}
		}
		return url;
	}

	/**
	 * https://stackoverflow.com/a/58362609
	 */
	private async ExitFullscreen() {
		try {
			// As correctly mentioned in the accepted answer, exitFullscreen only works on document
			const cancellFullScreen =
				document.exitFullscreen ||
				(document as any).mozCancelFullScreen ||
				(document as any).webkitExitFullscreen ||
				(document as any).msExitFullscreen;
			log.info("Exiting fullscreen");
			await cancellFullScreen.call(document);
			log.info("Exited fullscreen");
		} catch (err) {
			// Тут будет ошибка дескать "мы не вышли из фулскрина потому-что мы не там - нам не важно
			// ignore
		}
	}

	private GetSafeAreaMargins(): ISafeArea {
		const result: ISafeArea = {
			left: 0,
			right: 0,
			top: 0,
			bottom: 0
		};

		const topString = getComputedStyle(document.documentElement).getPropertyValue("--sat");
		const bottomString = getComputedStyle(document.documentElement).getPropertyValue("--sab");
		const leftString = getComputedStyle(document.documentElement).getPropertyValue("--sal");
		const rightString = getComputedStyle(document.documentElement).getPropertyValue("--sar");

		if (topString !== "") {
			const val = parseInt(topString);
			if (!isNaN(val)) {
				result.top = val;
			}
		}

		if (bottomString !== "") {
			const val = parseInt(bottomString);
			if (!isNaN(val)) {
				result.bottom = val;
			}
		}

		if (leftString !== "") {
			const val = parseInt(leftString);
			if (!isNaN(val)) {
				result.left = val;
			}
		}

		if (rightString !== "") {
			const val = parseInt(rightString);
			if (!isNaN(val)) {
				result.right = val;
			}
		}

		return result;
	}

	private SubscribeToScreenOrientationChange() {
		try {
			screen.orientation.addEventListener("change", (event) => {
				try {
					if (event.target == null) return;
					const target = event.target as ScreenOrientation;
					const type = target.type;
					const angle = target.angle;
					log.info(`ScreenOrientation change: ${type}, ${angle} degrees.`);
					log.info("New safe area: %o", this.GetSafeAreaMargins());

					this.IframeMessageEventOnChangeOrientation({
						orientationType: type,
						orientationAngle: angle,
						orientation: type.startsWith("portrait") ? "portrait" : "landscape",
						safeArea: this.GetSafeAreaMargins()
					});
				} catch (err) {
					log.error(err);
					log.warn("Failed to send orientation info");
				}
			});
		} catch (err) {
			log.warn(err);
			log.warn("Screen orientation api is not supported");
		}
	}

	async SendFxEvent(jsonString: string): Promise<IFxIdSdkSendFxEventResult> {
		if (this.fxEventsProcessor == null) {
			return {};
		}

		try {
			const event = JSON.parse(jsonString);
			await this.fxEventsProcessor.processEvent(event);
		} catch (ex) {
			log.error(ex);
		}

		return {};
	}

	private ConsentManagerStatus(): Promise<IFxIdSdkAdapterConsentManagerStatus> {
		// https://help.consentmanager.net/books/cmp/page/javascript-api
		if (window.__cmp != null) {
			log.info("[CMP] Checking CMP status");
			const x = window.__cmp("consentStatus", null, null, false);
			log.info("[CMP] CMP status: %o", x);
			if (typeof x === "object" && "consentExists" in x) {
				return Promise.resolve({
					consentExists: x.consentExists,
					consentData: x.consentData,
					cmpData: this.GetCmpData()
				});
			}
		}

		return Promise.resolve({ consentExists: false, consentData: {}, cmpData: {} });
	}

	private StartListenToCMPEvents() {
		// https://help.consentmanager.net/books/cmp/page/cmp-events
		if (window.__cmp != null) {
			log.info("[CMP] Starting to listen to cmp events");
			window.__cmp(
				"addEventListener",
				[
					"consent",
					(e: any, o: any) => {
						log.info("[CMP] Consent event. E: %o, O: %o", e, o);
						if (window.__cmp != null) {
							const x = window.__cmp("consentStatus", null, null, false);
							log.info("[CMP] CMP status: %o", x);
							if (typeof x === "object" && "consentExists" in x) {
								this.DispatchConsentManagerStatus({
									consentExists: x.consentExists,
									consentData: x.consentData,
									cmpData: this.GetCmpData()
								});
							}
						}
					},
					false
				],
				null
			);
		}
	}

	private GetCmpData(): any {
		if (window.__cmp != null) {
			log.info("[CMP] Getting cmp data");
			return JSON.parse(JSON.stringify(window.__cmp("getCMPData")));
		} else {
			log.warn("[CMP] No __cmp");
		}

		return {};
	}
}
