import {
	FxIdSdkAdapterSocialSettingDefault,
	FxIdSdkBaseAdapter,
	IExportedFxIdSdkMethod,
	IFxIdSdkAdapterBuyProductRequest,
	IFxIdSdkAdapterBuyProductResponse,
	IFxIdSdkAdapterInfoSocial,
	IFxIdSdkAdapterSocialSettings,
	IFxIdSdkAdapterStatEventRequest,
	IFxIdSdkAdapterStatInitializeRequest,
	IFxIdSdkInviteFriendsResult
} from "./FxIdSdkBaseAdapter";
import OpenApiClient, { openApiConfig } from "../Api/OpenApiClient";
import {
	FxIdApplicationStoreCreatePaymentHandlerEmbeddingType,
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxIdWebFeaturesPlayGamePublicConfigResponse
} from "../Api/gen";
import { sharingHandlersStore } from "../Stores/ShareHandlersStore";
import i18next from "i18next";
import { userStore } from "../Stores";
import { getQueriedProfileData } from "../Api/Queries";
import { ok, telegram, vk } from "vanilla-sharing";
import { appStore } from "../Stores/AppStore";
import { deferred } from "./FxIdSdkUtils";
import { useOs } from "@mantine/hooks";
import { openIframeModal, openLinkShareModal } from "../Components/Modals";
import { closeModal } from "@mantine/modals";

export interface FacebookMe {
	id: string;
	name: string;
	picture: {
		data: {
			height: number;
			is_silhouette: boolean;
			url: string;
			width: number;
		};
	};
}

export class FxIdSdkAdapterForFacebookGames extends FxIdSdkBaseAdapter {
	private buyProductResolve?: (data: any) => void;
	private buyProductReject?: (data: any) => void;
	private currentXsollaPaymentIsSuccessful = false;
	private lastPaymentModalId?: string;
	private _statusResponse?: facebook.StatusResponse;
	private _facebookMe?: FacebookMe;

	constructor(
		protected exportedSdk: IExportedFxIdSdkMethod,
		protected game: string,
		protected config: FxIdWebFeaturesPlayGamePublicConfigResponse
	) {
		super(exportedSdk);
	}

	async RegisterShareHandlers(): Promise<void> {
		const { enableLinkCopy, enableShareOk, enableShareTelegram, enableShareVk } = sharingHandlersStore.getState();
		const data = { url: window.location, title: i18next.t(`seo.${this.game}.title`) };
		enableShareVk(() => vk(data));
		enableShareOk(() => ok(data));
		enableShareTelegram(() => telegram(data));
		enableLinkCopy();

		const profileData = await getQueriedProfileData();

		const userDisplayName = profileData.Name ?? profileData.Email ?? `#${profileData.AccountId}`;

		userStore.getState().updateUserId(String(profileData.AccountId));
		userStore.getState().updateDisplayName(userDisplayName);

		return Promise.resolve();
	}

	SocialSettings(): Promise<IFxIdSdkAdapterSocialSettings> {
		return Promise.resolve(FxIdSdkAdapterSocialSettingDefault);
	}

	async BuyProduct(request: IFxIdSdkAdapterBuyProductRequest): Promise<IFxIdSdkAdapterBuyProductResponse> {
		const profileData = await getQueriedProfileData();

		const createPaymentResult = await OpenApiClient.Store.fxIdWebFeaturesStoreCreatePaymentEndpoint({
			Game: this.game,
			Sku: request.sku,
			WebPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FacebookGames,
			EmbeddingType: FxIdApplicationStoreCreatePaymentHandlerEmbeddingType.Embed
		});

		log.info("Received result from server: %o", createPaymentResult);

		const { promise, resolve, reject } = deferred<IFxIdSdkAdapterBuyProductResponse>();
		this.buyProductResolve = (data: any) => {
			log.info("Buy product resolved %o", data);
			resolve({ ...data, transactionId: createPaymentResult.TransactionId });
		};
		this.buyProductReject = (data: any) => {
			log.info("Buy product rejected %o", data);
			reject(new Error(data));
		};

		FB.ui(
			{
				method: "pay",
				action: "purchaseitem",
				product: createPaymentResult.FacebookGames!.ProductOgUrl,
				// quantity: 10, // optional, defaults to 1
				request_id: createPaymentResult.TransactionId // optional, must be unique for each payment
			},
			(response: facebook.PayDialogResponse) => {
				log.info("Received response from pay dialog: %o", response);
				if (response.error_code != null || response.status !== "completed") {
					this.buyProductReject!.call(this, response.error_message);
				} else {
					this.buyProductResolve!.call(this, response);
				}
			}
		);

		return promise;
	}

	async GetSocialInfo(): Promise<IFxIdSdkAdapterInfoSocial> {
		// const profileData = await getQueriedProfileData();
		return {
			social: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.FacebookGames,
			userId: this._facebookMe!.id,
			capabilities: []
		};
	}

	async Initialize(): Promise<void> {
		const {
			promise: facebookInitializedPromise,
			resolve: facebookInitializedResolve,
			reject: facebookInitializedReject
		} = deferred<void>();

		window.fbAsyncInit = () => {
			log.info("Initializing facebook with id: %o", this.config.PlatformData.FacebookGames!.AppId);
			FB.init({
				appId: this.config.PlatformData.FacebookGames!.AppId,
				xfbml: true,
				status: true,
				version: "v3.0"
			});

			facebookInitializedResolve();
		};

		log.info("Loading facebook script");
		await new Promise<void>((resolve) => {
			const s = document.createElement("script");
			s.type = "text/javascript";
			s.src = `https://connect.facebook.net/en_US/sdk.js`;
			s.async = true;
			s.crossOrigin = "anonymous";
			s.addEventListener(
				"load",
				(e) => {
					log.info("Facebook sdk loaded");
					resolve();
				},
				false
			);
			const head = document.getElementsByTagName("head")[0];
			head.appendChild(s);

			log.info("Facebook script loaded");
		});

		await facebookInitializedPromise;

		log.info("Facebook initialized");

		// https://developers.facebook.com/docs/games/build/legacy-web-games/get-started/login

		const {
			promise: facebookLoggedInPromise,
			resolve: facebookLoggedInPromiseResolve,
			reject: facebookLoggedInPromiseReject
		} = deferred<fb.StatusResponse>();

		log.info("Logging in to facebook");
		FB.getLoginStatus((response) => {
			log.info("Status: %o", response);
			// Check login status on load, and if the user is
			// already logged in, go directly to the welcome message.
			if (response.status === "connected") {
				facebookLoggedInPromiseResolve.call(this, response);
			} else {
				log.info("Logging in");
				// Otherwise, show Login dialog first.
				FB.login((response) => {
					log.info("Login response: %o", response);
					facebookLoggedInPromiseResolve.call(this, response);
				});
			}
		});

		this._statusResponse = await facebookLoggedInPromise;

		log.info("Facebook logged in: %o", this._statusResponse);

		const {
			promise: facebookMePromise,
			resolve: facebookMePromiseResolve,
			reject: facebookMePromiseReject
		} = deferred<FacebookMe>();

		FB.api("/me", { fields: "id, name, picture.width(100).height(100), currency" }, (response: FacebookMe) => {
			log.info("Successful FBLogin for %s: %o", response.name, response);
			facebookMePromiseResolve(response);
		});

		this._facebookMe = await facebookMePromise;
		log.info("Received me response: %o", this._facebookMe);
	}

	public GetLoginStatusResponse() {
		return this._statusResponse;
	}

	StoreCurrency(): Promise<string | undefined> {
		return Promise.resolve(undefined);
	}

	StatInitialize(request: IFxIdSdkAdapterStatInitializeRequest): Promise<void> {
		return Promise.resolve();
	}

	StatEvent(request: IFxIdSdkAdapterStatEventRequest): Promise<void> {
		return Promise.resolve(undefined);
	}

	async InviteFriends(): Promise<IFxIdSdkInviteFriendsResult> {
		const profileData = await getQueriedProfileData();
		// HACK: У нас нет способа забрать из OpenApiClient точный путь до эндпоинта с редиректом, поэтому собираем его
		// руками и надеемся, что все не сломается
		const redirectUrl = new URL(`${openApiConfig.basePath}/api/v1/go`);
		redirectUrl.searchParams.append("fxref", String(profileData.AccountId));
		redirectUrl.searchParams.append("url", window.location.toString());
		openLinkShareModal({ link: redirectUrl.toString() });
		return { friends: [] };
	}
}
