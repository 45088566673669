/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGames } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGames';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGamesFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGamesFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGamesToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGames';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRu } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRu';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRu';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassniki } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassniki';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassniki';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteam } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteam';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteamFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteamFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteamToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteam';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripe } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripe';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripe';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegram } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegram';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegram';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlay } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlay';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlay';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsolla } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsolla';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsolla';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGames } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGames';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGamesFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGamesFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGamesToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGames';
import type { FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassa } from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassa';
import {
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaFromJSONTyped,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaToJSON,
} from './FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassa';
import type { FxIdApplicationStoreOrderProductData } from './FxIdApplicationStoreOrderProductData';
import {
    FxIdApplicationStoreOrderProductDataFromJSON,
    FxIdApplicationStoreOrderProductDataFromJSONTyped,
    FxIdApplicationStoreOrderProductDataToJSON,
} from './FxIdApplicationStoreOrderProductData';
import type { FxIdDomainStoreEnumsMerchant } from './FxIdDomainStoreEnumsMerchant';
import {
    FxIdDomainStoreEnumsMerchantFromJSON,
    FxIdDomainStoreEnumsMerchantFromJSONTyped,
    FxIdDomainStoreEnumsMerchantToJSON,
} from './FxIdDomainStoreEnumsMerchant';

/**
 * 
 * @export
 * @interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
 */
export interface FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult {
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     * @deprecated
     */
    ConfirmationUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    TransactionId: string;
    /**
     * 
     * @type {FxIdDomainStoreEnumsMerchant}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    Merchant: FxIdDomainStoreEnumsMerchant;
    /**
     * 
     * @type {FxIdApplicationStoreOrderProductData}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    OrderProduct: FxIdApplicationStoreOrderProductData;
    /**
     * 
     * @type {number}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    OrderPrice: number;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    FacebookGames?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGames | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRu}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    MoneyMailRu?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRu | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegram}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    Telegram?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegram | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsolla}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    Xsolla?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsolla | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassa}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    Yookassa?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassa | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassniki}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    Odnoklassniki?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassniki | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlay}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    VkontaktePlay?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlay | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGames}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    YandexGames?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGames | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGames}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    AbsoluteGames?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGames | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripe}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    Stripe?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripe | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    WizQ?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQ | null;
    /**
     * 
     * @type {FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteam}
     * @memberof FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult
     */
    Steam?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteam | null;
}

/**
 * Check if a given object implements the FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult interface.
 */
export function instanceOfFxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "TransactionId" in value;
    isInstance = isInstance && "Merchant" in value;
    isInstance = isInstance && "OrderProduct" in value;
    isInstance = isInstance && "OrderPrice" in value;

    return isInstance;
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFromJSON(json: any): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult {
    return FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFromJSONTyped(json, false);
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ConfirmationUrl': !exists(json, 'ConfirmationUrl') ? undefined : json['ConfirmationUrl'],
        'TransactionId': json['TransactionId'],
        'Merchant': FxIdDomainStoreEnumsMerchantFromJSON(json['Merchant']),
        'OrderProduct': FxIdApplicationStoreOrderProductDataFromJSON(json['OrderProduct']),
        'OrderPrice': json['OrderPrice'],
        'FacebookGames': !exists(json, 'FacebookGames') ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesFromJSON(json['FacebookGames']),
        'MoneyMailRu': !exists(json, 'MoneyMailRu') ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuFromJSON(json['MoneyMailRu']),
        'Telegram': !exists(json, 'Telegram') ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramFromJSON(json['Telegram']),
        'Xsolla': !exists(json, 'Xsolla') ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaFromJSON(json['Xsolla']),
        'Yookassa': !exists(json, 'Yookassa') ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaFromJSON(json['Yookassa']),
        'Odnoklassniki': !exists(json, 'Odnoklassniki') ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiFromJSON(json['Odnoklassniki']),
        'VkontaktePlay': !exists(json, 'VkontaktePlay') ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayFromJSON(json['VkontaktePlay']),
        'YandexGames': !exists(json, 'YandexGames') ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGamesFromJSON(json['YandexGames']),
        'AbsoluteGames': !exists(json, 'AbsoluteGames') ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGamesFromJSON(json['AbsoluteGames']),
        'Stripe': !exists(json, 'Stripe') ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeFromJSON(json['Stripe']),
        'WizQ': !exists(json, 'WizQ') ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQFromJSON(json['WizQ']),
        'Steam': !exists(json, 'Steam') ? undefined : FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteamFromJSON(json['Steam']),
    };
}

export function FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultToJSON(value?: FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ConfirmationUrl': value.ConfirmationUrl,
        'TransactionId': value.TransactionId,
        'Merchant': FxIdDomainStoreEnumsMerchantToJSON(value.Merchant),
        'OrderProduct': FxIdApplicationStoreOrderProductDataToJSON(value.OrderProduct),
        'OrderPrice': value.OrderPrice,
        'FacebookGames': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFacebookGamesToJSON(value.FacebookGames),
        'MoneyMailRu': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultMoneyMailRuToJSON(value.MoneyMailRu),
        'Telegram': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultTelegramToJSON(value.Telegram),
        'Xsolla': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultXsollaToJSON(value.Xsolla),
        'Yookassa': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYookassaToJSON(value.Yookassa),
        'Odnoklassniki': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultOdnoklassnikiToJSON(value.Odnoklassniki),
        'VkontaktePlay': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultVkontaktePlayToJSON(value.VkontaktePlay),
        'YandexGames': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultYandexGamesToJSON(value.YandexGames),
        'AbsoluteGames': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultAbsoluteGamesToJSON(value.AbsoluteGames),
        'Stripe': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultStripeToJSON(value.Stripe),
        'WizQ': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultWizQToJSON(value.WizQ),
        'Steam': FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultSteamToJSON(value.Steam),
    };
}

