/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FastEndpointsErrorResponse,
  FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult,
  FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
  FxIdWebFeaturesStoreContinueRedirectWithPiRequest,
  FxIdWebFeaturesStoreContinueRedirectWithPiResponse,
  FxIdWebFeaturesStoreCreatePaymentRequest,
  FxIdWebFeaturesStoreGetPaymentStatusRequest,
  FxIdWebFeaturesStoreGetPaymentStatusResponse,
  FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest,
  FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponse,
  FxIdWebFeaturesStoreStoreAvailabilityResponse,
  FxIdWebFeaturesStoreStoreDataRequest,
  FxIdWebFeaturesStoreStoreDataResponse,
} from '../models';
import {
    FastEndpointsErrorResponseFromJSON,
    FastEndpointsErrorResponseToJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFromJSON,
    FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultToJSON,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformFromJSON,
    FxIdDomainStoreEnumsSupportedWebPublishingPlatformToJSON,
    FxIdWebFeaturesStoreContinueRedirectWithPiRequestFromJSON,
    FxIdWebFeaturesStoreContinueRedirectWithPiRequestToJSON,
    FxIdWebFeaturesStoreContinueRedirectWithPiResponseFromJSON,
    FxIdWebFeaturesStoreContinueRedirectWithPiResponseToJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestFromJSON,
    FxIdWebFeaturesStoreCreatePaymentRequestToJSON,
    FxIdWebFeaturesStoreGetPaymentStatusRequestFromJSON,
    FxIdWebFeaturesStoreGetPaymentStatusRequestToJSON,
    FxIdWebFeaturesStoreGetPaymentStatusResponseFromJSON,
    FxIdWebFeaturesStoreGetPaymentStatusResponseToJSON,
    FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequestFromJSON,
    FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequestToJSON,
    FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponseFromJSON,
    FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponseToJSON,
    FxIdWebFeaturesStoreStoreAvailabilityResponseFromJSON,
    FxIdWebFeaturesStoreStoreAvailabilityResponseToJSON,
    FxIdWebFeaturesStoreStoreDataRequestFromJSON,
    FxIdWebFeaturesStoreStoreDataRequestToJSON,
    FxIdWebFeaturesStoreStoreDataResponseFromJSON,
    FxIdWebFeaturesStoreStoreDataResponseToJSON,
} from '../models';

export interface FxIdWebFeaturesStoreContinueRedirectWithPiEndpointRequest {
    fxIdWebFeaturesStoreContinueRedirectWithPiRequest: FxIdWebFeaturesStoreContinueRedirectWithPiRequest;
}

export interface FxIdWebFeaturesStoreCreatePaymentEndpointRequest {
    fxIdWebFeaturesStoreCreatePaymentRequest: FxIdWebFeaturesStoreCreatePaymentRequest;
}

export interface FxIdWebFeaturesStoreCreatePaymentWithRedirectEndpointRequest {
    game: string | null;
    sku: string | null;
    token: string | null;
    webPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform;
    currency?: string | null;
    country?: string | null;
    developerPayload?: string | null;
}

export interface FxIdWebFeaturesStoreGetPaymentStatusEndpointRequest {
    fxIdWebFeaturesStoreGetPaymentStatusRequest: FxIdWebFeaturesStoreGetPaymentStatusRequest;
}

export interface FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectEndpointRequest {
    fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest: FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest;
}

export interface FxIdWebFeaturesStoreStoreDataEndpointRequest {
    fxIdWebFeaturesStoreStoreDataRequest: FxIdWebFeaturesStoreStoreDataRequest;
}

/**
 * 
 */
export class StoreApi extends runtime.BaseAPI {

    /**
     */
    async fxIdWebFeaturesStoreContinueRedirectWithPiEndpointRaw(requestParameters: FxIdWebFeaturesStoreContinueRedirectWithPiEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesStoreContinueRedirectWithPiResponse>> {
        if (requestParameters.fxIdWebFeaturesStoreContinueRedirectWithPiRequest === null || requestParameters.fxIdWebFeaturesStoreContinueRedirectWithPiRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesStoreContinueRedirectWithPiRequest','Required parameter requestParameters.fxIdWebFeaturesStoreContinueRedirectWithPiRequest was null or undefined when calling fxIdWebFeaturesStoreContinueRedirectWithPiEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/store/continue_redirect_with_pi`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesStoreContinueRedirectWithPiRequestToJSON(requestParameters.fxIdWebFeaturesStoreContinueRedirectWithPiRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesStoreContinueRedirectWithPiResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesStoreContinueRedirectWithPiEndpoint(fxIdWebFeaturesStoreContinueRedirectWithPiRequest: FxIdWebFeaturesStoreContinueRedirectWithPiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesStoreContinueRedirectWithPiResponse> {
        const response = await this.fxIdWebFeaturesStoreContinueRedirectWithPiEndpointRaw({ fxIdWebFeaturesStoreContinueRedirectWithPiRequest: fxIdWebFeaturesStoreContinueRedirectWithPiRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesStoreCreatePaymentEndpointRaw(requestParameters: FxIdWebFeaturesStoreCreatePaymentEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult>> {
        if (requestParameters.fxIdWebFeaturesStoreCreatePaymentRequest === null || requestParameters.fxIdWebFeaturesStoreCreatePaymentRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesStoreCreatePaymentRequest','Required parameter requestParameters.fxIdWebFeaturesStoreCreatePaymentRequest was null or undefined when calling fxIdWebFeaturesStoreCreatePaymentEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/store/create_payment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesStoreCreatePaymentRequestToJSON(requestParameters.fxIdWebFeaturesStoreCreatePaymentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResultFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesStoreCreatePaymentEndpoint(fxIdWebFeaturesStoreCreatePaymentRequest: FxIdWebFeaturesStoreCreatePaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdApplicationStoreCreatePaymentHandlerCreatePaymentPublicResult> {
        const response = await this.fxIdWebFeaturesStoreCreatePaymentEndpointRaw({ fxIdWebFeaturesStoreCreatePaymentRequest: fxIdWebFeaturesStoreCreatePaymentRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesStoreCreatePaymentWithRedirectEndpointRaw(requestParameters: FxIdWebFeaturesStoreCreatePaymentWithRedirectEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.game === null || requestParameters.game === undefined) {
            throw new runtime.RequiredError('game','Required parameter requestParameters.game was null or undefined when calling fxIdWebFeaturesStoreCreatePaymentWithRedirectEndpoint.');
        }

        if (requestParameters.sku === null || requestParameters.sku === undefined) {
            throw new runtime.RequiredError('sku','Required parameter requestParameters.sku was null or undefined when calling fxIdWebFeaturesStoreCreatePaymentWithRedirectEndpoint.');
        }

        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling fxIdWebFeaturesStoreCreatePaymentWithRedirectEndpoint.');
        }

        if (requestParameters.webPublishingPlatform === null || requestParameters.webPublishingPlatform === undefined) {
            throw new runtime.RequiredError('webPublishingPlatform','Required parameter requestParameters.webPublishingPlatform was null or undefined when calling fxIdWebFeaturesStoreCreatePaymentWithRedirectEndpoint.');
        }

        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        if (requestParameters.currency !== undefined) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.country !== undefined) {
            queryParameters['country'] = requestParameters.country;
        }

        if (requestParameters.webPublishingPlatform !== undefined) {
            queryParameters['webPublishingPlatform'] = requestParameters.webPublishingPlatform;
        }

        if (requestParameters.developerPayload !== undefined) {
            queryParameters['developerPayload'] = requestParameters.developerPayload;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/store/create_payment_with_redirect/{game}/{sku}`.replace(`{${"game"}}`, encodeURIComponent(String(requestParameters.game))).replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters.sku))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async fxIdWebFeaturesStoreCreatePaymentWithRedirectEndpoint(game: string | null, sku: string | null, token: string | null, webPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform, currency?: string | null, country?: string | null, developerPayload?: string | null, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.fxIdWebFeaturesStoreCreatePaymentWithRedirectEndpointRaw({ game: game, sku: sku, token: token, webPublishingPlatform: webPublishingPlatform, currency: currency, country: country, developerPayload: developerPayload }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesStoreGetPaymentStatusEndpointRaw(requestParameters: FxIdWebFeaturesStoreGetPaymentStatusEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesStoreGetPaymentStatusResponse>> {
        if (requestParameters.fxIdWebFeaturesStoreGetPaymentStatusRequest === null || requestParameters.fxIdWebFeaturesStoreGetPaymentStatusRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesStoreGetPaymentStatusRequest','Required parameter requestParameters.fxIdWebFeaturesStoreGetPaymentStatusRequest was null or undefined when calling fxIdWebFeaturesStoreGetPaymentStatusEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWTBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v1/store/get_payment_status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesStoreGetPaymentStatusRequestToJSON(requestParameters.fxIdWebFeaturesStoreGetPaymentStatusRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesStoreGetPaymentStatusResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesStoreGetPaymentStatusEndpoint(fxIdWebFeaturesStoreGetPaymentStatusRequest: FxIdWebFeaturesStoreGetPaymentStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesStoreGetPaymentStatusResponse> {
        const response = await this.fxIdWebFeaturesStoreGetPaymentStatusEndpointRaw({ fxIdWebFeaturesStoreGetPaymentStatusRequest: fxIdWebFeaturesStoreGetPaymentStatusRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectEndpointRaw(requestParameters: FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponse>> {
        if (requestParameters.fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest === null || requestParameters.fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest','Required parameter requestParameters.fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest was null or undefined when calling fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/store/merchant_payment_completed`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequestToJSON(requestParameters.fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectEndpoint(fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest: FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesStoreMerchantPaymentCompletedRedirectResponse> {
        const response = await this.fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectEndpointRaw({ fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest: fxIdWebFeaturesStoreMerchantPaymentCompletedRedirectRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesStoreStoreAvailabilityEndpointRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesStoreStoreAvailabilityResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v1/store/store_availability`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesStoreStoreAvailabilityResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesStoreStoreAvailabilityEndpoint(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesStoreStoreAvailabilityResponse> {
        const response = await this.fxIdWebFeaturesStoreStoreAvailabilityEndpointRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async fxIdWebFeaturesStoreStoreDataEndpointRaw(requestParameters: FxIdWebFeaturesStoreStoreDataEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FxIdWebFeaturesStoreStoreDataResponse>> {
        if (requestParameters.fxIdWebFeaturesStoreStoreDataRequest === null || requestParameters.fxIdWebFeaturesStoreStoreDataRequest === undefined) {
            throw new runtime.RequiredError('fxIdWebFeaturesStoreStoreDataRequest','Required parameter requestParameters.fxIdWebFeaturesStoreStoreDataRequest was null or undefined when calling fxIdWebFeaturesStoreStoreDataEndpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v1/store/store_data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FxIdWebFeaturesStoreStoreDataRequestToJSON(requestParameters.fxIdWebFeaturesStoreStoreDataRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FxIdWebFeaturesStoreStoreDataResponseFromJSON(jsonValue));
    }

    /**
     */
    async fxIdWebFeaturesStoreStoreDataEndpoint(fxIdWebFeaturesStoreStoreDataRequest: FxIdWebFeaturesStoreStoreDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FxIdWebFeaturesStoreStoreDataResponse> {
        const response = await this.fxIdWebFeaturesStoreStoreDataEndpointRaw({ fxIdWebFeaturesStoreStoreDataRequest: fxIdWebFeaturesStoreStoreDataRequest }, initOverrides);
        return await response.value();
    }

}
