/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook } from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook';
import type { FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogle } from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogle';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogleFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogleFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogleToJSON,
} from './FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogle';

/**
 * 
 * @export
 * @interface FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
 */
export interface FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig {
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
     */
    AllowedAuthProviders: Array<string>;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogle}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
     */
    Google?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogle | null;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook}
     * @memberof FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig
     */
    Facebook?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebook | null;
}

/**
 * Check if a given object implements the FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig interface.
 */
export function instanceOfFxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "AllowedAuthProviders" in value;

    return isInstance;
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFromJSON(json: any): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig {
    return FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFromJSONTyped(json, false);
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'AllowedAuthProviders': json['AllowedAuthProviders'],
        'Google': !exists(json, 'Google') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogleFromJSON(json['Google']),
        'Facebook': !exists(json, 'Facebook') ? undefined : FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookFromJSON(json['Facebook']),
    };
}

export function FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigToJSON(value?: FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'AllowedAuthProviders': value.AllowedAuthProviders,
        'Google': FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigGoogleToJSON(value.Google),
        'Facebook': FxIdDomainSettingsPublicWebClientConfigOptionsAuthConfigFacebookToJSON(value.Facebook),
    };
}

